.navbar {
	background-color: rgb(255, 255, 255, 0.8);
}

.navbar-brand {
	max-width: 75%;
}

.offcanvas {
	width: 200px;
}

.logo {
	height: 100px !important;
	transition: 0.5s;
}

.logo-shrinked {
	height: 40px !important;
	transition: 0.5s;
}