.feature {
	height: 60px;
	width: 60px;
	background-color: #bd6500;
	color: white;
	border-radius: 50%;
	text-align: center;
	align-items: center;
	display: flex;
	margin: auto;
}

.hclist {
	margin: 0;
	padding: 6px 0 6px 32px;
	list-style: none;
	background-image: url("/public/images/check.svg");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 16px;
}

.hcbutton {
	border-radius: 3px;
	border-color: #E05800;
	background-color: #E05800;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
	color: white;
	font-size: medium;
	width: 250px;
}

.hcbutton:hover {
	border-color: #E05800;
	background-color: #e05a00a0;
}