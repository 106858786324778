footer {
	font-size: 10pt;
}

footer a:link{
	text-decoration: none;
}

footer a:link:hover {
	text-decoration: underline;
}