@keyframes appearing {
	0% {opacity:0;}
	25% {opacity:1;}
	100% {opacity:1;}
}

@keyframes appearing1 {
	0% {opacity:0;}
	25% {opacity:0;}
	50% {opacity:1;}
	100% {opacity:1;}
}

@keyframes appearing2 {
	0% {opacity:0;}
	75% {opacity:0;}
	100% {opacity:1;}
}

.img-container {
	position: relative;
}

.img-overlay {
	position:	absolute;
	top:		0;
	left:		0;
	width:		100%;
	height:		100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.img-text {
	position: absolute;
	font-family:scriptina;
	color:white;
	animation-duration: 4s;
	animation-name:appearing;
}

.img-text-1 {
	position: absolute;
	font-family:scriptina;
	color:white;
	animation-duration: 4s;
	animation-name:appearing1;
}

.img-text-2 {
	position: absolute;
	font-family:scriptina;
	color:white;
	animation-duration: 4s;
	animation-name:appearing2;
}

.click:hover {
	color: white;
	background-color: #E05800;
	cursor: pointer;
}

.click:active {
	color: white;
	background-color: #E05800;
	cursor: pointer;
}